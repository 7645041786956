import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export class Slider extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Carousel
                className={ this.props.modifierClass ? this.props.modifierClass : ''}
                showIndicators={this.props.showIndicators}
                showStatus={false}
                infiniteLoop={this.props.autoPlay}
                autoPlay={this.props.autoPlay}
                showThumbs={this.props.showThumbs}
                swipeable={!this.props.notSwipeable}
                preventMovementUntilSwipeScrollTolerance={true}
                interval={7000}
                thumbWidth={90}>
                {this.props.children}
            </Carousel>
        );
    }
}
export default Slider
