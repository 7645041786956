import React from "react";

const ProductCarouselInfo = ({id, position}) => {
    return (
        <>
            {/*berry green protein*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwODgyOTQ1MTQ3NTg=' &&
            <div className={`txt--center ${position === 'top' ? 'mb--20 d-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <img src="/images/supplements/circles-bgp.png" width="457" height="85" alt="Info about Berry Green Protein." className="no-shadow"/>
            </div>
            }
            {/*vanilla protein*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMjE0NjA1NjQxNjA=' &&
            <div className={`txt--center ${position === 'top' ? 'mb--20 d-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <img src="/images/supplements/circles-vp.png" width="457" height="96" alt="Info about Vanilla Protein." className="no-shadow"/>
            </div>
            }
            {/*full body collagen*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNjg2NjQ4NTQ1OTg=' &&
            <div className={`txt--center ${position === 'top' ? 'mb--20 d-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <img src="/images/supplements/circles-fbc.png" width="457" height="85" alt="Info about Full Body Collagen." className="no-shadow"/>
            </div>
            }
            {/*serenicalm*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2Nzk2MjAwMzQ2MzA=' &&
            <div className={`${position === 'top' ? 'd-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <div className="row flex-column flex-md-row align-items-center">
                    <div className="col-8 col-md-6">
                        <h4 className="h4--supplements txt--uppercase img--arrow-abs img--arrow-abs-blue"><strong>reducing your stress can...</strong></h4>
                    </div>
                    <div className="col-10 col-md-6 p--0">
                        <ul className="list--sm checkmark--supplements checkmark--supplements-blue">
                            <li>Keep you from snacking</li>
                            <li>Boost your immune system</li>
                            <li>Reduce fat storage</li>
                            <li>Improve focus and concentration</li>
                            <li>Help you make better decisions</li>
                        </ul>
                    </div>
                </div>
            </div>
            }
            {/*whole sleep*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ0MjM4OTU1ODA3NDI=' &&
            <div className={`${position === 'top' ? 'd-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <div className="row flex-column flex-md-row align-items-center">
                    <div className="col-8 col-md-6">
                        <h4 className="h4--supplements txt--uppercase img--arrow-abs"><strong>A quality night's rest will...</strong></h4>
                    </div>
                    <div className="col-10 col-md-6 p--0">
                        <ul className="list--sm p--0 checkmark--supplements checkmark--supplements-purple">
                            <li>Improve memory, alertness and focus</li>
                            <li>More efficiently break down fat</li>
                            <li>More efficiently add lean muscle</li>
                            <li>Balance and improve mood</li>
                            <li>Heal and restore the body each day</li>
                        </ul>
                    </div>
                </div>
            </div>
            }
            {/*rock and restore*/}
            {id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3NTIwMzM3MzQ4NDg=' &&
            <div className={`txt--center ${position === 'top' ? 'mb--20 d-none d-lg-block' : 'mt--20 d-block d-lg-none'}`}>
                <img src="/images/supplements/circles-rr.png" width="417" height="78" alt="Info about Rock and Restore." className="no-shadow"/>
            </div>
            }
        </>
    )
}
export default ProductCarouselInfo
