import React, {useEffect, useState} from "react";
import {Link} from "gatsby";


const ProductBox = ({ handlePopup, handleProduct, type, item, handleShopify, product }) => {

    const [title, setTitle] = useState('')
    const [notice, setNoticeText] = useState('')

    useEffect(() => {
        if (item.title === 'I ❤ Vanilla Protein') {
            setTitle("I <i class='fas fa-heart txt--pink'></i> Vanilla Protein")
        } else {
            setTitle(item.title)
        }
    },[])

    useEffect(() => {

        if(item.variants[0].compareAtPrice) {
            setNoticeText('sale')
        }

        let createdDate = new Date(item.createdAt);
        let today = new Date();

        // if(checkDate(createdDate, today)) {
        //     setNoticeText('new')
        // }
        if(item.handle === 'vanilla-protein' || item.handle === 'rock-and-restore') {
            setNoticeText('new')
        }

    }, [item])

    const checkDate = (createdDate, today) => {

        let productYear = createdDate.getFullYear()
        let productMonth = createdDate.getMonth()

        let compareYear = today.getFullYear()
        let compareMonth = today.getMonth()

        // check if product was created this month or the previous month in the same year
        return ((productMonth === compareMonth) || (productMonth === compareMonth - 1) ) && (productYear === compareYear)
    }

    let icon = [
        (type.toLowerCase() === 'workout plan' && "flex") ||
        (type.toLowerCase() === 'meal plan' && "utensils") ||
        (type.toLowerCase() === 'supplements' && "leaf")
    ]

    const handleClick = () => {
        handlePopup(true)
        handleProduct(product)
        handleShopify(item)
    }

    return (
        <div className={ `product-box ${ notice ? 'product-box--notice' : '' }` } data-notice={ notice ? notice : ''}>
            <Link to={`/products/${item.handle}`} className="product-box__img">
                <span className={`product-box--tag product-box--tag-${icon}`}><span>{type}</span><i className={`fas fa-${icon}`}/></span>
                <img src={`/images/product-box/${product.img}.png`} width="629" height="323" alt=""/>
                <img src={`/images/product-box/${product.img}-hover.jpg`} width="1140" height="924" alt="" className="lazy product-box__img--hover"/>
            </Link>
            <div className="product-box__content">
                <h4 className="product-box__title"><Link to={`/products/${item.handle}`} dangerouslySetInnerHTML={{ __html: title }}/></h4>
                <p>{item.description}</p>

                <div className="product-box__buttons">
                    <Link to={`/products/${item.handle}`} className="btn btn--md mb--20">learn more</Link>
                    <button onClick={handleClick} className="link link--sm">Buy now</button>
                </div>

            </div>
        </div>
    )
}
export default ProductBox
