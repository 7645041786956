import React, {useState, useEffect} from "react";
import Slider from "../components/Slider"
import {Link} from "gatsby"
import productData from "../../static/data/product";
import ProductCarouselInfo from "./ProductCarouselInfo";
import ThemeContext  from "../context/ThemeContext";


const ProductInfo = ({popup,handlePopup, shopify, product, order}) => {

    const [paymentType, setPaymentType] = useState('one')
    const [count, setCount] = useState(1)

    const [sellingPlan, setSellingPlan] = useState('')
    const [sellingPlanOptions, setSellingPlanOptions] = useState([])

    const [orderId, setOrderId] = useState(0)

    const [gallery, setGallery] = useState([])

    const [title, setTitle] = useState('')

    const [shopifyVariantId, setShopifyVariantId] = useState('')

    const [alreadyInCart, setAlreadyInCart] = useState(false)

    const [paymentPlanPrice, setPaymentPlanPrice] = useState(0)
    const [paymentPlanInterval, setPaymentPlanInterval] = useState(1)
    const [paymentPlanMonthly, setPaymentPlanMonthly] = useState(0)

    const [subscriptionPrice, setSubscriptionPrice] = useState(0);

    const handleChange = (e) => {
        setPaymentType(e.target.value)
    }

    useEffect(()=>{

        if(shopifyVariantId && productData) {
            if(productData[shopifyVariantId].sellingPlans) {
                const item = productData[shopifyVariantId].sellingPlans.filter((item) => item.sellingPlanId === sellingPlan);
                const price = item[0].price;
                const months = item[0].months;
                if (price && months) {
                    setSubscriptionPrice(price / months)
                } else {
                    setSubscriptionPrice(((shopify.variants[0].price) * 89.99 / 100).toFixed(2));
                }
            }
        }

    },[sellingPlan])

    const handleOverflow = () => {
        document.body.classList.remove('overflow--hidden');
    }

    useEffect(() => {

        let options = [];
        let paymentMonthly;
        if(shopifyVariantId && productData) {
            if(productData[shopifyVariantId].sellingPlans) {
                productData[shopifyVariantId].sellingPlans.forEach((item, index) => {
                    options = ([...options, item])
                })
                setSellingPlanOptions(options)

                if (options.length === 1 && shopify.variants[0].compareAtPrice) {
                    setPaymentPlanPrice( shopify.variants[0].compareAtPrice - productData[shopifyVariantId].sellingPlans[0].price )
                    setPaymentPlanInterval(productData[shopifyVariantId].sellingPlans[0].months)

                    paymentMonthly = Number((shopify.variants[0].compareAtPrice - (shopify.variants[0].compareAtPrice - productData[shopifyVariantId].sellingPlans[0].price)) / productData[shopifyVariantId].sellingPlans[0].months)
                    setPaymentPlanMonthly(paymentMonthly)
                }
            }

        }

    },[shopifyVariantId])

    useEffect(() => {
        if(sellingPlanOptions.length) {
            setSellingPlan(Number(sellingPlanOptions[0].sellingPlanId))
        }
    },[sellingPlanOptions])

    useEffect(() => {
        if (shopify.title === 'I ❤ Vanilla Protein') {
            setTitle("I <i class='fas fa-heart txt--pink'></i> Vanilla Protein")
        } else {
            setTitle(shopify.title)
        }

        if(shopify.variants[0].id.indexOf("Shopify__ProductVariant__") !== -1) {
            setShopifyVariantId(shopify.variants[0].id.split("Shopify__ProductVariant__")[1])
        }
    },[shopify])

    useEffect(() => {
        if (popup) setPaymentType("one")
    }, [popup])

    useEffect(() => {
        setOrderId(order)
    }, [order])

    useEffect(() => {
        if(product) {
            let images = [];
            if(shopify.images.length > 1) {

                shopify.images.map((item) => {
                    images = [...images, item.originalSrc]
                })


                if(product.video) {
                    images = [...images, product.video]
                }

                setGallery(images)
            }
        }
    }, [shopify])

    return (
        <ThemeContext.Consumer>
            { theme => (
                 (product) ? (
                     <>
                         {
                             (() => {
                                 if( theme.cart.length ) {
                                     setAlreadyInCart(false)
                                     theme.cart.map((item) => {
                                         if(item.title === shopify.title) {
                                             setAlreadyInCart(true)
                                         }
                                     })
                                 } else {
                                     setAlreadyInCart(false)
                                 }
                             })()
                         }
                         <div className="row">
                             {/*gallery*/}
                             <div className="col-12 col-lg-6">

                                 <div className="product-images">
                                     {gallery.length > 0 ? <>
                                             { shopify.images.length === 1 &&
                                             <img src={shopify.images[0].originalSrc} alt=""/>
                                             }
                                             { shopify.images.length > 1 &&
                                             <Slider
                                                 modifierClass="product-carousel"
                                                 showIndicators={false}
                                                 autoPlay={false}
                                                 showThumbs={true}
                                             >
                                                 {gallery.map( (item, index) => (
                                                     (index === gallery.length - 1) && productData[shopifyVariantId].video ? (
                                                         <>
                                                             <img src={`/images/video-thumbs/${productData[shopifyVariantId].img}-video.png`} alt="" className="no-shadow video-thumb"/>
                                                             <div key={index} className="video bg--white video--16-9">
                                                                 <iframe src={`https://player.vimeo.com/video/${item}`}
                                                                         width="640"
                                                                         height="360" frameBorder="0"
                                                                         allow="autoplay; fullscreen; picture-in-picture"
                                                                         allowFullScreen/>
                                                             </div>
                                                         </>
                                                     ) : (
                                                         <img key={index} src={item} alt="" className="no-shadow"/>
                                                     )
                                                 ))}
                                             </Slider>
                                             }
                                         </> :
                                         <span className="img-loader"/>
                                     }
                                 </div>

                                 <ProductCarouselInfo id={shopify.shopifyId} position="top" />

                             </div>
                             {/*content*/}
                             <div className="col-12 col-lg-6">
                                 <div className="product">
                                     <div className="product__quote">
                                         <p><i>“{product.quote}”</i></p>
                                         <p><strong className="fw--bold">- {product.quoteAuthor}</strong></p>
                                     </div>
                                     <h2 className="product__title" dangerouslySetInnerHTML={{__html: title}}/>
                                     <p className="product__description">{shopify.description}</p>
                                     <div className="product__price">
                                         <p className="txt--pink"><strong>${Number(shopify.variants[0].price)}</strong></p>
                                     </div>

                                     {product.type === 'supplements' ? (
                                         <>
                                             <div className="product__type mb--15">
                                                 <div role="button" onClick={() => setPaymentType('one')} className={`product__type__box ${ (paymentType === 'one') ? 'active' : '' }`}>
                                                     <div className="d-flex justify-content-between align-items-center">
                                                         <div className="input-field">
                                                             <input
                                                                 type="radio"
                                                                 name={`product-type-${orderId}`}
                                                                 value="one"
                                                                 checked={paymentType === 'one'}
                                                                 onChange={(e) => handleChange(e)}
                                                             />
                                                             <label>One-time payment</label>
                                                         </div>
                                                         <p className="mb--0"><strong>${Number(shopify.variants[0].price)}</strong></p>
                                                     </div>
                                                 </div>
                                                 <div role="button" onClick={() => setPaymentType('two')} className={`product__type__box ${ (paymentType === 'two') ? 'active' : '' }`}>
                                                     <div className="d-flex justify-content-between align-items-center mb--5">
                                                         <div className="input-field">
                                                             <input
                                                                 type="radio"
                                                                 name={`product-type-${orderId}`}
                                                                 value="two"
                                                                 checked={paymentType === 'two'}
                                                                 onChange={(e) => handleChange(e)}
                                                             />
                                                             <label>Subscribe & Save 10% <small className="txt--pink">(<i className="fas fa-star"/> Recommended)</small></label>
                                                         </div>
                                                         <p className="txt--pink mb--0"><strong>${ (Number(shopify.variants[0].price) * 89.99/100).toFixed(2)}</strong></p>
                                                     </div>
                                                     <p className="txt--14 m--0">Products are automatically delivered on your schedule. <br/> No obligation, modify or cancel your subscription anytime.</p>
                                                 </div>
                                             </div>
                                             <div className="row">
                                                 <div className="col-6 col-lg-5">
                                                     <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row mb--20">
                                                         <label htmlFor="count" className="mr--15"><strong>Quantity</strong></label>
                                                         <div className="input-field input-field--number input-field--number-lg m--0">
                                                             <button
                                                                 className="step step--down"
                                                                 onClick={(e) => {e.preventDefault(); (count - 1 >= 1) && setCount(+count - 1);}}
                                                             ><i className="fas fa-minus" aria-label="Minus"/></button>
                                                             <input
                                                                 type="number"
                                                                 value={count}
                                                                 min="1"
                                                                 id="count"
                                                                 onChange={e => setCount(+e.target.value)}
                                                             />
                                                             <button
                                                                 className="step step--up"
                                                                 onClick={(e) => {e.preventDefault(); setCount(+count + 1)}}
                                                             ><i className="fas fa-plus" aria-label="Plus"/></button>
                                                         </div>
                                                     </div>
                                                 </div>
                                                 { paymentType === 'two' &&
                                                 <div className="col-6 col-lg-7">
                                                     <div className="input d-flex align-items-start align-items-sm-center flex-column flex-sm-row mb--20">
                                                         <label className="mr--15"><strong>Deliver Every</strong></label>
                                                         <select value={sellingPlan} onChange={(event) => setSellingPlan(Number(event.target.value))}>
                                                             {
                                                                 sellingPlanOptions.map((item, index) => (
                                                                     <option key={index} value={item.sellingPlanId}>{item.orderIntervalFrequency} Days</option>
                                                                 ))
                                                             }
                                                         </select>
                                                     </div>
                                                 </div>
                                                 }
                                             </div>
                                         </>
                                     ) : (
                                         <>
                                             { product.paymentOptions === 2 &&
                                             <div className="product__type mb--15">
                                                 <div role="button" onClick={() => setPaymentType('one')} className={`product__type__box ${ (paymentType === 'one') ? 'active' : '' }`}>
                                                     <div className="d-flex justify-content-between align-items-center">
                                                         <div className="input-field">
                                                             <input
                                                                 type="radio"
                                                                 id="onetime"
                                                                 name={`product-type-${orderId}`}
                                                                 value="one"
                                                                 checked={paymentType === 'one'}
                                                                 onChange={(e) => handleChange(e)}
                                                             />
                                                             <label htmlFor="onetime">One-time payment</label>
                                                         </div>
                                                         <p className="mb--0"><strong>${Number(shopify.variants[0].price)}</strong></p>
                                                     </div>
                                                 </div>
                                                 <div role="button" onClick={() => setPaymentType('two')} className={`product__type__box ${ (paymentType === 'two') ? 'active' : '' }`}>
                                                     <div className="d-flex justify-content-between align-items-center mb--5">
                                                         <div className="input-field">
                                                             <input
                                                                 type="radio"
                                                                 id="two"
                                                                 name={`product-type-${orderId}`}
                                                                 value="two"
                                                                 checked={paymentType === 'two'}
                                                                 onChange={(e) => handleChange(e)}
                                                             />
                                                             <label htmlFor="two">Payment Plan
                                                                 {paymentPlanPrice ?
                                                                     <small className="txt--pink"> (Save ${Number( paymentPlanPrice )})</small> : ''}
                                                             </label>
                                                         </div>
                                                         <p className="txt--pink mb--0">
                                                             <strong>${paymentPlanMonthly} </strong>
                                                             <small>x {paymentPlanInterval} months</small>
                                                         </p>
                                                     </div>
                                                     <p className="txt--14 m--0">You will be charged ${paymentPlanMonthly} today, and an additional ${paymentPlanMonthly} next { (paymentPlanInterval - 1) > 1 ? `${(paymentPlanInterval - 1)} months` : ' month' }.</p>
                                                 </div>
                                             </div>}
                                         </>
                                     )}
                                     { popup ? (
                                         <div className="d-flex flex-column align-items-center justify-content-center">
                                             <div className="d-flex align-items-center justify-content-center info-buttons">

                                                 { alreadyInCart && product.type !== 'supplements' ?
                                                     <button disabled className="btn btn--md mb--10">added to cart</button> :
                                                     <>
                                                         {paymentType === 'one' && <button onClick={() => {theme.addVariantToCart(shopify.variants[0].id, count, shopify.variants[0].price, 'No', shopify.title); handlePopup()}} className="btn btn--md mb--10">add to cart</button>}
                                                         {paymentType === 'two' && <button onClick={() => {theme.addVariantToCart(shopify.variants[0].id, count, Number(subscriptionPrice), sellingPlan, shopify.title); handlePopup()}} className="btn btn--md mb--10">add to cart</button>}
                                                     </>
                                                 }

                                                 <Link to={`/products/${shopify.handle}`} onClick={handleOverflow} className="btn btn--md btn--outline ml--10 mb--10">Learn More</Link>
                                             </div>
                                             <img src="/images/credit-cards.png" alt="" width="300" height="39" className="popup__credit-cards no-shadow"/>
                                         </div>
                                     ) : (
                                         <div className="d-flex align-items-center flex-column flex-sm-row flex-wrap justify-content-center justify-content-lg-start">
                                             {paymentType === 'one' && <button onClick={() => {theme.addVariantToCart(shopify.variants[0].id, count, shopify.variants[0].price, 'No', shopify.title);}} className="btn btn--md mb--10">add to cart</button>}
                                             {paymentType === 'two' && <button onClick={() => {theme.addVariantToCart(shopify.variants[0].id, count, Number(subscriptionPrice), sellingPlan, shopify.title);}} className="btn btn--md mb--10">add to cart</button>}
                                             <p className="txt--14 ml--30">Shipping to <img src="/images/usa.png" width={40} height={20} alt="" className="ml--10 radius-0 no-shadow"/></p>
                                         </div>
                                     )}

                                     <ProductCarouselInfo id={shopify.shopifyId} position="bottom" />
                                 </div>
                             </div>
                        </div>
                     </>
                 ) : ''
            )}
        </ThemeContext.Consumer>
     )
}
export default ProductInfo
