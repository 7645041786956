import React, {useEffect, useState} from "react";
import ProductInfo from "./ProductInfo";

const QuickBuyPopup = ({open, handlePopup, shopify, product} ) => {

    const [popup, setPopup] = useState(false)

    useEffect(() => {
        setPopup(true)

        if (typeof window !== 'undefined' && open) {
            document.body.classList.add('overflow--hidden');
        }

    },[open])

    const closePopup = () => {
        document.body.classList.remove('overflow--hidden');
        setPopup(false)
        handlePopup(false)
    }

    return (
        <>
            { open ? (
                <span
                    role="button"
                    onKeyDown={closePopup}
                    onClick={closePopup}
                    tabIndex={0}
                    aria-label="Close Popup."
                    className="overlay"/>
            ) : ''}
            <div id="popup" className="popup popup--xl popup-product" style={ open ? { display: 'block'} : { display: 'none'}} >
                <button onClick={closePopup} className="popup__close"><i className="fal fa-times"/></button>
                <ProductInfo popup={popup} handlePopup={closePopup} shopify={shopify} product={product}/>
            </div>
        </>

    )
}
export default QuickBuyPopup
